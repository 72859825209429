$(document).ready(function(){

	projectstabs();
	loadMore();

	$('a[href*="#"]')
	  	// Remove links that don't actually link to anything
	  	.not('[href="#"]')
	  	.not('[href="#0"]')
	  	.click(function(event) {
	    	// On-page links
	    	if (
		      	location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
		      	&& 
		      	location.hostname == this.hostname
	    	) {
	      	// Figure out element to scroll to
	      	var target = $(this.hash);
		      	target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		      	// Does a scroll target exist?
		      	if (target.length) {
			        // Only prevent default if animation is actually gonna happen
			        event.preventDefault();
			        $('html, body').animate({
			          scrollTop: target.offset().top
			        }, 1000, function() {
			          // Callback after animation
			          // Must change focus!
			          var $target = $(target);
			          $target.focus();
			          if ($target.is(":focus")) { // Checking if the target was focused
			            return false;
			          } else {
			            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
			            $target.focus(); // Set focus again
			          };
			        });
		    	}
	    	}
	  	});
	$(window).load(function(){

		sliders();
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

	 	const icons = document.querySelectorAll('.hamburger');
	    	icons.forEach(icon => {
	        	icon.addEventListener('click', (event) => {
	            icon.classList.toggle("open");
	        });
	    });

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
				$('.menu-container .menu-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : $navHeight
				});
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');
			
			$('.menu-container .menu-contain').css({
				'padding-bottom' : 0,
				'padding-top' : 0
			});

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});


		
		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

	});
});
// Select all links with hashes
$(document).ready(function(){
	$('ul.tabs .tab-header').eq(0).addClass('current');
	$('.inner-wrapper .tab-content').eq(0).addClass('current');
	$('ul.tabs .tab-header').click(function(){
		var tab_id = $(this).attr('data-tab');

		$('ul.tabs .tab-header').removeClass('current');
		$('.tab-content').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
	})

});



function loadMore() {
	$(".tab-content-wrapper .tab-item-wrapper").slice(0, 0).show();
	$("#proj-loadmore").on("click", function(e){
		e.preventDefault();
		$(".tab-content-wrapper .tab-item-wrapper:hidden").slice(0, 4).slideDown();
		if($(".tab-content-wrapper .tab-item-wrapper:hidden").length == 0) {
			$("#loadMore").text("No Content").addClass("noContent");
		}
	});
}


function projectstabs() {
	$("body").on("click", ".tabs-heading-wrapper .item a", function(e){
		e.preventDefault();
		var tabkey = $(this).data("tabkey");
		if ( !$(this).hasClass("active") ) {
			$(".tabs-heading-wrapper .item a").removeClass("active");
			$(".tab-content-wrapper .tab-item-wrapper").removeClass("active");
			$(this).addClass("active");
			$(".tab-content-wrapper").each(function(){
				var contentTabkey = $(this).data("tabkey");
				if ( tabkey == contentTabkey ) {
					$(this).addClass("active");
				} else {
					$(this).removeClass("active");
				}
			});
		}
	});
}